import * as TextVariables from "../../assets/Variables/TextVariables.js";
import Colors from "../../assets/Variables/ColorsVariables.js";
import { Spaces } from "../../assets/Variables/SpaceVariables.js";

export const mainPaper = {
  display: "flex",
  flexDirection: "column",
  width: "calc(100vw - 280px)",
  maxWidth: "1110px",
  borderRadius: "12px",
  padding: Spaces.Size_5,
  gap: Spaces.Size_8,
};

export const linkButton = {
  width: "118px",
  fontFamily: TextVariables.Caption_2,
  color: Colors.Secondary_800,
  textTransform: "none",
  display: "flex",
  alignItems: "center",
  padding: `${Spaces.Size_1} ${Spaces.Size_2}`,
  gap: "2px",
  borderRadius: Spaces.Size_2,
};
