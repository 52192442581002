import englishFlag from "../assets/English.svg";
import usaFlag from "../assets/USA.svg"

export const languages = [{ label: "ENG", value: "en", flag: englishFlag }];

export const flags = [{ label: "USD", value: "USD", flag: usaFlag }];

export const yesNo = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
];

export const withdrawalMethods = [
  {
    label: "Cryptowallet",
    value: "Cryptowallet",
    requiredFields: ["network", "wallet"],
  },
  {
    label: "Bank wire transfer",
    value: "Bank wire transfer",
    requiredFields: [
      "amount",
      "withdrawalMethod",
      "beneficiaryName",
      "beneficiaryAddress",
      "accountNumber",
      "bankName",
      "swiftCode",
      "abaNumber",
      "sortCode",
      "referenceNumber",
      "associatedEmail",
      "intermediaryBank",
      "intermediaryBankName",
      "intermediaryBankAddress",
      "swiftCodeIntermediaryBank",
      "ibanNumberIntermediaryBank",
      "abaNumberIntermediaryBank",
      "sortCodeIntermediaryBank",
    ],
  },
  {
    label: "Neteller",
    value: "Neteller",
    requiredFields: ["amount", "withdrawalMethod", "associatedEmail"],
  },
  {
    label: "Skrill",
    value: "Skrill",
    requiredFields: ["amount", "withdrawalMethod", "associatedEmail"],
  },
  {
    label: "Uphold",
    value: "Uphold",
    requiredFields: ["amount", "withdrawalMethod", "associatedEmail"],
  },
  {
    label: "BitPay",
    value: "BitPay",
    requiredFields: ["amount", "withdrawalMethod", "associatedEmail", "wallet"],
  },
  {
    label: "Bitwallet",
    value: "Bitwallet",
    requiredFields: ["amount", "withdrawalMethod", "associatedEmail"],
  },
  {
    label: "SticPay",
    value: "SticPay",
    requiredFields: ["amount", "withdrawalMethod", "associatedEmail"],
  },
  {
    label: "Advcash",
    value: "Advcash",
    requiredFields: ["amount", "withdrawalMethod", "associatedEmail"],
  },
  {
    label: "Interac e-Transfer",
    value: "Interac e-Transfer",
    requiredFields: ["amount", "withdrawalMethod", "associatedEmail"],
  },
  {
    label: "Fasapay",
    value: "Fasapay",
    requiredFields: [
      "amount",
      "withdrawalMethod",
      "associatedEmail",
      "accountNumber",
    ],
  },
  { label: "UnionPay", value: "UnionPay", requiredFields: [
    "accountNumber",
    "amount",
    "beneficiaryBankName",
    "branchName",
    "province",
    "city"
  ] },
  {
    label: "JPay",
    value: "JPay",
    requiredFields: [
      "amount",
      "withdrawalMethod",
      "beneficiaryName",
      "beneficiaryAddress",
      "accountNumber",
      "bankName",
      "swiftCode",
      "abaNumber",
      "sortCode",
      "referenceNumber",
      "associatedEmail",
      "intermediaryBank",
      "intermediaryBankName",
      "intermediaryBankAddress",
      "swiftCodeIntermediaryBank",
      "ibanNumberIntermediaryBank",
      "abaNumberIntermediaryBank",
      "sortCodeIntermediaryBank",
    ],
  },
  { label: "JpConnect", value: "JpConnect", requiredFields: ["amount", "withdrawalMethod"] },
];

export const cryptoWalletNetworks = [
  { label: "ERC", value: "ERC" },
  { label: "TRC 20", value: "TRC 20" },
  { label: "Bitcoin", value: "Bitcoin" },
];

export const beneficiaryBanks = [
  { label: "共和国银行", value: "共和国银行" },
  { label: "共和国银行2", value: "共和国银行2" },
  { label: "农业银行", value: "农业银行" },
];
