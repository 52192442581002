import { Button } from "@mui/material";
import * as TextVariables from "../../assets/Variables/TextVariables.js";
import { Spaces } from "../../assets/Variables/SpaceVariables.js";
import Colors from "../../assets/Variables/ColorsVariables.js";

function ContinueButton({ children, HandleOnClick, disable, id }) {
  return (
    <Button
      id={id}
      onClick={HandleOnClick}
      disabled={disable ? disable : false}
      type="submit"
      sx={{
        backgroundColor: disable ? Colors.Secondary_100 : Colors.Primary_700,
        fontFamily: TextVariables.Caption_1_Bold,
        lineHeight: 1,
        color: "white",
        textTransform: "none",
        display: "flex",
        padding: `${Spaces.Size_4} ${Spaces.Size_6}`,
        justifyContent: "center",
        alignItems: "center",
        gap: Spaces.Size_2,
        borderRadius: Spaces.Size_2,
        "&:hover": {
          backgroundColor: "#FF0000",
          opacity: 0.8,
        },
      }}
    >
      {children}
    </Button>
  );
}

export default ContinueButton;
