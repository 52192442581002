import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import { useTranslation } from "react-i18next";
import Cookies from "universal-cookie";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import logo_tradeview from "../../assets/logo_tradeview_white.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { useCookies } from "../../Tools/CookieChecker";
import * as TextVariables from "../../assets/Variables/TextVariables.js";
import Colors from "../../assets/Variables/ColorsVariables.js";
import DashboardBackground from "../Backgrounds/DashboardBackground";
import * as styles from "../NavAndSide/Styles.js";
import {
  Box,
  Link,
  Button,
  Popover,
  List,
  ListItem,
  IconButton,
  Typography,
  Portal,
  Badge,
  styled,
  Chip,
} from "@mui/material";
import LanguageSelector from "../../components/Selectors/languageSelector.js";

const NavbarAndSidebar = (props) => {
  const { user, name, type, token } = useCookies();
  const [userName, setUserName] = useState("");
  const [avatarLetter, setAvatarLetter] = useState("");
  const [avatarColor, setAvatarColor] = useState("");
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const cookies = new Cookies();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openProfileModal, setopenProfileModal] = useState(null);
  const [viewNoRead, setviewNoRead] = useState(false);
  const [activeButton, setActiveButton] = useState("all");

  const handleFilterClick = (button) => {
    if (button === "all") {
      setviewNoRead(false);
    } else {
      setviewNoRead(true);
    }

    setActiveButton(button);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfileClick = (event) => {
    setopenProfileModal(event.currentTarget);
  };

  const handleProfileClose = () => {
    setopenProfileModal(null);
  };

  const open = Boolean(anchorEl);
  const openProfile = Boolean(openProfileModal);
  const id = open ? "simple-popover" : undefined;

  const notifications = [
    {
      title: "New Referral",
      text: "New referral link added, check it",
      date: "2024/07/29 13:59:05",
      view: false,
    },
    {
      title: "Don't forget to refer",
      text: "Remember to refer your trees to your clients",
      date: "2024/07/28 12:59:06",
      view: true,
    },
    {
      title: "New Report of your referrals",
      text: "Check the new report done for you about your referrals",
      date: "2024/07/27 14:59:07",
      view: true,
    },
  ];

  const StyledButton = styled(Button)(({ active, ...other }) => ({
    padding: 0,
    minWidth: 0,
    textTransform: "none",
    color: active ? Colors.Secondary_700 : Colors.Secondary_400,
    fontFamily: active
      ? TextVariables.Caption_1_Bold.fontFamily
      : TextVariables.Caption_1.fontFamily,
    fontWeight: active
      ? TextVariables.Caption_1_Bold.fontWeight
      : TextVariables.Caption_1.fontWeight,
    fontSize: active
      ? TextVariables.Caption_1_Bold.fontSize
      : TextVariables.Caption_1.fontSize,
  }));

  useEffect(() => {
    if (name) {
      setUserName(name);
      setAvatarLetter(name.split(" ")[0][0]);
      setAvatarColor(stringToColor(name));
    }
  }, [name]);

  const changeLang = (e) => {
    i18n.changeLanguage(e);
    cookies.set("lang", e, { path: "/" });
  };

  function stringToColor(string) {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
  }

  const getButtonColor = (menuItem) => {
    if (props.sidebarOption === menuItem) {
      return {
        backgroundColor: Colors.Secondary_50,
        color: "#15202A",
      };
    }
    return {};
  };

  const handleNavigate = (path) => {
    navigate(path);
  };

  const handleHome = () => {
    navigate("/dashboard");
  };

  const handleSettingsPage = () => {
    navigate("/settings");
  };

  const handleLogout = () => {
    navigate("/login");
  };

  useEffect(() => {
    if (location.pathname.includes("/my-trees") && type === "IB") {
      navigate("/");
    }
  }, [location, navigate, type]);

  return (
    <Box sx={{ height: "100%", margin: "0", padding: "0" }}>
      <DashboardBackground></DashboardBackground>
      <Box sx={styles.navbar}>
        <IconButton
          sx={{
            backgroundColor: "transparent",
            padding: 0,
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
          onClick={(e) => handleHome()}
        >
          <img src={logo_tradeview} alt="Tradeview" width={"200"} />
        </IconButton>
        <Box sx={styles.perfilContainer}>
          <Button sx={styles.notificationButton} onClick={handleClick}>
            <Badge
              badgeContent={notifications.length}
              color="error"
              overlap="circular"
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <NotificationsNoneIcon
                style={{ fontSize: 24, color: "#333333" }}
              />
            </Badge>
          </Button>
          <Portal>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              PaperProps={{
                sx: {
                  maxHeight: "416px",
                  overflowY: "hidden",
                  minWidth: 362,
                },
              }}
            >
              <Box sx={styles.notificationMainContainer}>
                <Box sx={styles.notificationTitleFilterContainer}>
                  <Box sx={styles.notificationTitleContainer}>
                    <Typography sx={{ fontFamily: TextVariables.Body_3_Bold }}>
                      Notifications
                    </Typography>
                    <span className="material-symbols-outlined">settings</span>
                  </Box>
                  <Box sx={styles.notificationFiltersContainer}>
                    <Box sx={styles.filterButtonsContainer}>
                      <StyledButton
                        active={activeButton === "all"}
                        onClick={() => handleFilterClick("all")}
                      >
                        All
                      </StyledButton>
                      <StyledButton
                        active={activeButton === "unread"}
                        onClick={() => handleFilterClick("unread")}
                      >
                        Unread
                      </StyledButton>
                    </Box>
                    <Chip label={`2 new`} sx={styles.ChipBuyStyle} />
                  </Box>
                </Box>

                {notifications.length > 0 ? (
                  <List sx={styles.notificationList}>
                    {notifications.map((notification, index) => (
                      <Box key={index}>
                        {(notification.view || !viewNoRead) && (
                          <ListItem
                            sx={{ padding: 0 }}
                            button
                            onClick={() =>
                              alert(`Notification: ${notification}`)
                            }
                          >
                            <Box sx={styles.selectItemStyle}>
                              <Box sx={styles.notificationBody}>
                                <Box sx={styles.selectItemContent}>
                                  <Typography
                                    sx={{
                                      fontFamily: TextVariables.Caption_1_Bold,
                                    }}
                                  >
                                    {notification.title}
                                  </Typography>
                                  <Typography
                                    sx={{ fontFamily: TextVariables.Overline }}
                                  >
                                    {notification.text}
                                  </Typography>
                                </Box>
                                {notification.view && (
                                  <Box paddingTop="4px">
                                    <Box sx={styles.noReadIcon}></Box>
                                  </Box>
                                )}
                              </Box>

                              <Typography
                                sx={{
                                  fontFamily: TextVariables.Overline,
                                  color: Colors.Secondary_300,
                                }}
                              >
                                {notification.date}
                              </Typography>
                            </Box>
                          </ListItem>
                        )}
                      </Box>
                    ))}
                  </List>
                ) : (
                  <Typography sx={{ p: 2 }}>
                    There are no pending notifications
                  </Typography>
                )}
              </Box>
            </Popover>
          </Portal>
          <LanguageSelector></LanguageSelector>
          <Button sx={styles.profileBox} onClick={handleProfileClick}>
            <Avatar
              sx={{
                alt: userName,
                bgcolor: avatarColor,
                width: 20,
                height: 20,
                fontSize: 15,
                padding: 2,
              }}
            >
              {avatarLetter}
            </Avatar>
            <Box sx={styles.nameBox}>
              <Typography
                sx={{ fontFamily: TextVariables.Caption_1, lineHeight: "1" }}
              >
                Hello
              </Typography>
              <Typography
                sx={{ fontFamily: TextVariables.Body_3_Bold, lineHeight: "1" }}
              >
                {name}
              </Typography>
            </Box>
            <span className="material-symbols-outlined">expand_more</span>
          </Button>
          <Popover
            id={id}
            open={openProfile}
            anchorEl={openProfileModal}
            onClose={handleProfileClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            PaperProps={{
              sx: {
                maxHeight: "416px", // Altura máxima del popover para evitar expandir demasiado
                overflowY: "hidden", // Habilita el scroll si el contenido es grande
              },
            }}
          >
            <Box sx={styles.modalProfile}>
              <Box sx={styles.modalProfileTitle}>
                <Avatar
                  sx={{
                    alt: userName,
                    bgcolor: avatarColor,
                    width: 20,
                    height: 20,
                    fontSize: 15,
                    padding: 2,
                  }}
                >
                  {avatarLetter}
                </Avatar>
                <Box sx={styles.nameBox}>
                  <Typography
                    sx={{
                      fontFamily: TextVariables.Body_3_Bold,

                      lineHeight: "1",
                    }}
                  >
                    {name}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: TextVariables.Caption_1,
                      lineHeight: "1",
                    }}
                  >
                    {user}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Button sx={styles.profileSelector} onClick={handleSettingsPage}>
                  <span
                    className="material-symbols-outlined"
                    style={{ color: Colors.Secondary_900 }}
                  >
                    settings
                  </span>
                  <Typography
                    sx={{ fontFamily: TextVariables.Caption_1, width: "100%" }}
                  >
                    Settings
                  </Typography>
                </Button>
                <Button sx={styles.profileSelector} onClick={handleLogout}>
                  <span
                    className="material-symbols-outlined"
                    style={{ color: Colors.Secondary_900 }}
                  >
                    logout
                  </span>
                  <Typography
                    sx={{ fontFamily: TextVariables.Caption_1, width: "100%" }}
                  >
                    Log Out
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Popover>
        </Box>
      </Box>

      <Box sx={styles.sideBar}>
        <Box sx={styles.nav}>
          <Link
            sx={styles.tab}
            style={getButtonColor("Dashboard")}
            component={RouterLink}
            to="/dashboard"
          >
            <span
              className="material-symbols-outlined"
              style={{ fontSize: "20px" }}
            >
              trending_up
            </span>
            Dashboard
          </Link>
          <Link
            sx={styles.tab}
            style={getButtonColor("Clients")}
            component={RouterLink}
            to="/my-clients"
          >
            <span
              className="material-symbols-outlined"
              style={{ fontSize: "20px" }}
            >
              group
            </span>
            Clients
          </Link>
          <Link
            sx={styles.tab}
            style={getButtonColor("Wallet")}
            component={RouterLink}
            to="/my-wallet"
          >
            <span
              className="material-symbols-outlined"
              style={{ fontSize: "20px" }}
            >
              account_balance_wallet
            </span>
            Wallet
          </Link>
          {type === "BROKER" || type === "MASTER_IB" ? (
            <Link
              sx={styles.tab}
              style={getButtonColor("My trees")}
              component={RouterLink}
              to="/my-trees"
            >
              <span
                className="material-symbols-outlined"
                style={{ fontSize: "20px" }}
              >
                account_tree
              </span>
              Trees
            </Link>
          ) : null}
          <Link
            sx={styles.tab}
            style={getButtonColor("Referral Links")}
            component={RouterLink}
            to="/referral-links"
          >
            <span
              className="material-symbols-outlined"
              style={{ fontSize: "20px" }}
            >
              support_agent
            </span>
            Referral Links
          </Link>
          <Link
            sx={styles.tab}
            style={getButtonColor("Request")}
            component={RouterLink}
            to="/requests"
            onClick={() => handleNavigate("/requests")}
          >
            <span
              className="material-symbols-outlined"
              style={{ fontSize: "20px" }}
            >
              share
            </span>
            Requests
          </Link>
          {/* <Link
            sx={styles.tab}
            style={getButtonColor("Settings")}
            component={RouterLink}
            to="/settings"
            onClick={() => handleNavigate("/settings")}
          >
            <span
              className="material-symbols-outlined"
              style={{ fontSize: "20px" }}
            >
              manage_accounts
            </span>
            Settings
          </Link> */}
        </Box>
      </Box>
      <Box sx={styles.content}>{props.children}</Box>
    </Box>
  );
};

export default NavbarAndSidebar;
