import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Paper, Button, Box, Chip } from "@mui/material";
import BreadkCrumbComponent from "../../components/BreadCrumbs/BreadkCrumbComponent";
import IconComponent from "../../components/Icons/IconComponent";
import * as styles from "../Clients/Styles.js";
import FixedTablecomponent from "../../components/Table/FixedTablecomponent";
import TapComponent from "../../components/Taps/TapComponent";
import TableComponent from "../../components/Table/TableComponent";
import SearchBoxComponent from "../../components/SearchBox/SearchBoxComponent";
import CustomTapPanelComponent from "../../components/Taps/CustomTapPanelComponent";
import TableToolTip from "../../components/ToolTips/TableToolTip";
import TitledSelector from "../../components/Selectors/TitledSelector";
import DateSelector from "../../components/Selectors/DateSelector";
import { useCookies } from "../../Tools/CookieChecker.js";
import ClientInfoRequest from "../../Endpoints/ClientInfoRequest.js";
import OpenOrdersRequest from "../../Endpoints/OpenOrdersRequest";
import HistoryOrdersRequest from "../../Endpoints/HistoryOrdersRequest";
import {Spaces, mainBox} from "../../assets/Variables/SpaceVariables.js";
import {
  SetDashboardData,
  GetDashboardData,
  RemoveDashboardData,
} from "../../Tools/LocalStorage.js";
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import {
  getLocalTimeZoneOffsetInSeconds,
  getTimestamps,
  ConvertUTCToDate,
} from "../../Tools/DateTimeConvertions";

const dateOptions = [
  { label: "Today", value: "Today" },
  { label: "This week", value: "This week" },
  { label: "This month", value: "This month" },
  { label: "This year", value: "This year" },
  { label: "Custom", value: "Custom" },
];

function ClientShowPage(props) {
  const { user, type, token } = useCookies();
  const { id } = useParams();
  const navigate = useNavigate();
  const [clientInfo, setClientInfo] = useState({});
  const [t] = useTranslation("global");
  const [tabstate, setTabstate] = useState(0);
  const [rowsOpenTrades, setRowsOpenTrades] = useState([]);
  const [filteredRowsOpenTrades, setFilteredRowsOpenTrades] = useState(rowsOpenTrades);
  const [rowsHistory, setRowsHistory] = useState([]);
  const [filteredRowsHistory, setFilteredRowsHistory] = useState(rowsHistory);
  const [dateSelect, setDateSelect] = useState("Today");
  const [showDataPickers, setShowDataPickers] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const RequestDashboardData = async (datetime) => {
    try {
      const response = await ClientInfoRequest(datetime);
      if (response.data != null) {
        SetDashboardData(response);
        const storedClients = response.data.data.clients;
        if (id >= 0 && id < storedClients.length) {
          storedClients[id].registration_date = storedClients[
            id
          ].registration_date
            .replace("T", " ")
            .replace("Z", " ")
            .replace("-", "/")
            .replace("-", "/");
          setClientInfo(storedClients[id]);
          RequestOpenOrderData({
            login: String(storedClients[id].login),
            platform: storedClients[id].server,
          });
        } else {
          navigate("/my-clients");
        }
      } else {
        RemoveDashboardData();
      }
    } catch (error) {
      console.log(error);
      RemoveDashboardData();
    }
  };

  const RequestOpenOrderData = async (params) => {
    try {
      const response = await OpenOrdersRequest(params);
      if (response.data.data != null) {
        const storedTrades = response.data.data.trades;
        if (
          storedTrades &&
          Array.isArray(storedTrades) &&
          storedTrades.length > 0
        ) {
          let actualRows = storedTrades.map((trade, index) => {
            let tradeType;
            if (trade.cmd == 0) tradeType = "Buy";
            else if (trade.cmd == 1) tradeType = "Sell";
            else tradeType = "Other";

            let row = {
              id: index,
              ticket: trade.order,
              open_time: ConvertUTCToDate(trade.open_time),
              symbol: trade.symbol,
              type: tradeType,
              volume: (trade.volume / 100).toFixed(2),
              open_price: trade.open_price.toFixed(5),
              sl: trade.sl.toFixed(5),
              tp: trade.tp.toFixed(5),
              payout: trade.commission_agent.toFixed(2),
              swap: trade.storage.toFixed(2),
              profit: trade.profit.toFixed(2),
              comment: trade.comment,
            };
            return row;
          });
          setRowsOpenTrades(actualRows);
          setFilteredRowsOpenTrades(actualRows);
        }
      }
    } catch (error) {
      console.log(error);
      RemoveDashboardData();
    }
  };

  const RequestHistoryOrderData = async (params) => {
    try {
      const response = await HistoryOrdersRequest(params);
      if (response.data != null) {
        const storedTrades = response.data.data.trades;
        if (
          storedTrades &&
          Array.isArray(storedTrades) &&
          storedTrades.length > 0
        ) {
          let actualRows = storedTrades.map((trade, index) => {
            let tradeType;
            if (trade.cmd == 0) tradeType = "Buy";
            else if (trade.cmd == 1) tradeType = "Sell";
            else tradeType = "Other";

            let row = {
              id: index,
              ticket: trade.order,
              open_time: ConvertUTCToDate(trade.open_time),
              symbol: trade.symbol,
              type: tradeType,
              volume: (trade.volume / 100).toFixed(2),
              open_price: trade.open_price.toFixed(5),
              sl: trade.sl.toFixed(5),
              tp: trade.tp.toFixed(5),
              close_price: trade.close_price,
              close_time: ConvertUTCToDate(trade.close_time),
              payout: trade.commission_agent.toFixed(2),
              swap: trade.storage.toFixed(2),
              profit: trade.profit.toFixed(2),
            };
            return row;
          });
          setRowsHistory(actualRows);
          setFilteredRowsHistory(actualRows);
        }
      }
    } catch (error) {
      console.log(error);
      RemoveDashboardData();
    }
  };

  useEffect(() => {
    let dataResponse = GetDashboardData();
    if (dataResponse.dataExits) {
      const storedClients = dataResponse.clients;
      if (id >= 0 && id < storedClients.length) {
        let clientSelected = storedClients[id];
        clientSelected.registration_date = clientSelected.registration_date
          .replace("T", " ")
          .replace("Z", " ")
          .replace("-", "/")
          .replace("-", "/");
        setClientInfo(clientSelected);
        RequestOpenOrderData({
          login: String(clientSelected.login),
          platform: clientSelected.server,
        });

        setRowsHistory([]);
        setFilteredRowsHistory([]);
        let datetime = getTimestamps("Today");
        RequestHistoryOrderData({
          login: String(clientSelected.login),
          platform: clientSelected.server,
          from: String(datetime.from),
          to: String(datetime.to),
        });
      } else {
        navigate("/my-clients");
      }
    } else {
      let datetime = getTimestamps("Today");
      RequestDashboardData({
        from: datetime.from,
        to: datetime.to,
      });
    }
  }, []);

  const handleDateSelector = (event) => {
    setDateSelect(event.target.value);
    if (event.target.value === "Custom") {
      setShowDataPickers(true);
    } else {
      setShowDataPickers(false);
    }
  };
  const handleFromChange = (date) => {
    setFromDate(date);
  };
  const handleToChange = (date) => {
    setToDate(date);
  };
  const handleTapStatechange = (value) => {
    setTabstate(value);
    setFilteredRowsOpenTrades(rowsOpenTrades);
    setFilteredRowsHistory(rowsHistory);
  };

  const columnsOpenTrades = [
    {
      field: "ticket",
      headerClassName: "header_table",
      renderHeader: () => (
        <TableToolTip title="Order identification number">
          <Box>
            {"Ticket "}
            <IconComponent icon="help" size="12px" />
          </Box>
        </TableToolTip>
      ),
    },
    {
      field: "open_time",
      headerClassName: "header_table",
      renderHeader: () => <>{"Open Time"}</>,
      hide: true
    },
    {
      field: "symbol",
      headerClassName: "header_table",
      renderHeader: () => <>{"Symbol"}</>,
    },
    {
      field: "type",
      headerClassName: "header_table",
      renderHeader: () => <>{"Type"}</>,
      renderCell: (params) => {
        if (params.row.type.toLowerCase().includes("buy")) {
          return <Chip label={params.row.type} sx={styles.ChipBuyStyle} />;
        } else {
          return <Chip label={params.row.type} sx={styles.ChipSellStyle} />;
        }
      },
    },
    {
      field: "volume",
      headerClassName: "header_table",
      renderHeader: () => <>{"Vol"}</>,
    },
    {
      field: "open_price",
      headerClassName: "header_table",
      renderHeader: () => <>{"Open Price"}</>,
    },
    {
      field: "sl",
      headerClassName: "header_table",
      renderHeader: () => <>{"SL"}</>,
    },
    {
      field: "tp",
      headerClassName: "header_table",
      renderHeader: () => <>{"TP"}</>,
    },
    ...(type && type === 'IB') ? [] : [
      {
        field: "payout",
        headerClassName: "header_table",
        renderHeader: () => <>{"Payout"}</>,
      }
    ],
    {
      field: "swap",
      headerClassName: "header_table",
      renderHeader: () => <>{"Swap"}</>,
    },
    {
      field: "profit",
      headerClassName: "header_table",
      renderHeader: () => <>{"Profit/Loss"}</>,
      renderCell: (params) => {
        if (params.row.profit >= 0) {
          return <Box sx={styles.ProfitStyles}>{params.row.profit}</Box>;
        } else {
          return <Box sx={styles.LossStyles}>{params.row.profit}</Box>;
        }
      },
    },
    {
      field: "comment",
      headerClassName: "header_table",
      renderHeader: () => <>{"Comment"}</>,
    },
  ];

  const columnsHistory = [
    {
      field: "ticket",
      headerClassName: "header_table",
      renderHeader: () => (
        <TableToolTip title="Order identification number">
          <Box>
            {"Ticket "}
            <IconComponent icon="help" size="12px" />
          </Box>
        </TableToolTip>
      ),
    },
    {
      field: "open_time",
      headerClassName: "header_table",
      renderHeader: () => <>{"Open Time"}</>,
    },
    {
      field: "symbol",
      headerClassName: "header_table",
      renderHeader: () => <>{"Symbol"}</>,
    },
    {
      field: "type",
      headerClassName: "header_table",
      renderHeader: () => <>{"Type"}</>,
      renderCell: (params) => {
        if (params.row.type.toLowerCase().includes("buy")) {
          return <Chip label={params.row.type} sx={styles.ChipBuyStyle} />;
        } else {
          return <Chip label={params.row.type} sx={styles.ChipSellStyle} />;
        }
      },
    },
    {
      field: "volume",
      headerClassName: "header_table",
      renderHeader: () => <>{"Vol"}</>,
    },
    {
      field: "open_price",
      headerClassName: "header_table",
      renderHeader: () => <>{"Open Price"}</>,
    },
    {
      field: "sl",
      headerClassName: "header_table",
      renderHeader: () => <>{"SL"}</>,
    },
    {
      field: "tp",
      headerClassName: "header_table",
      renderHeader: () => <>{"TP"}</>,
    },
    {
      field: "close_price",
      headerClassName: "header_table",
      renderHeader: () => <>{"Close Price"}</>,
    },
    {
      field: "close_time",
      headerClassName: "header_table",
      renderHeader: () => <>{"Close Time"}</>,
    },
    ...(type && type === 'IB') ? [] : [
      {
        field: "payout",
        headerClassName: "header_table",
        renderHeader: () => <>{"Payout"}</>,
      }
    ],
    {
      field: "profit",
      headerClassName: "header_table",
      renderHeader: () => <>{"Profit/Loss"}</>,
      renderCell: (params) => {
        if (params.row.profit >= 0) {
          return <Box sx={styles.ProfitStyles}>{params.row.profit}</Box>;
        } else {
          return <Box sx={styles.LossStyles}>{params.row.profit}</Box>;
        }
      },
    },
  ];

  const handleFilteredRows = (rowsevent) => {
    setFilteredRowsOpenTrades(rowsevent);
  };
  const handleFilteredRowsHistory = (rowsevent) => {
    setFilteredRowsHistory(rowsevent);
  };
  const handleDownload = () => {
    if(tabstate === 0){
      if (!Array.isArray(filteredRowsOpenTrades) || filteredRowsOpenTrades.length === 0) {
        console.error("OpenTrades list are empty");
        return;
      }
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(filteredRowsOpenTrades);
      XLSX.utils.book_append_sheet(wb, ws, "Open Trading Data");
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, `[${clientInfo.login}] Open Trades data.xlsx`);
    }
    else{
      if (!Array.isArray(filteredRowsHistory) || filteredRowsHistory.length === 0) {
        console.error("HistoryTrades list are empty");
        return;
      }
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(filteredRowsHistory);
      XLSX.utils.book_append_sheet(wb, ws, "History Trading Data");
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, `[${clientInfo.login}] History Trades data.xlsx`);
    }
  };

  const handleRequestHistory = () => {
    if (dateSelect != "Custom") {
      setRowsHistory([]);
      setFilteredRowsHistory([]);
      let datetime = getTimestamps(dateSelect);
      RequestHistoryOrderData({
        login: String(clientInfo.login),
        platform: clientInfo.server,
        from: String(datetime.from),
        to: String(datetime.to),
      });
    } else {
      if (fromDate != null || toDate != null) {
        setRowsHistory([]);
        setFilteredRowsHistory([]);
        RequestHistoryOrderData({
          login: String(clientInfo.login),
          platform: clientInfo.server,
          from: String(fromDate / 1000 + getLocalTimeZoneOffsetInSeconds()),
          to: String(toDate / 1000 + getLocalTimeZoneOffsetInSeconds() + 86400),
        });
      }
    }
  };

  return (
    <Box sx={mainBox}>
      <Paper
        elevation={2}
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: Spaces.Size_5,
          width: "calc(100vw - 280px)",
          maxWidth: "1110px",
          borderRadius: "12px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <BreadkCrumbComponent
            backPageLink={"/my-clients"}
            clientNumber={clientInfo.login}
            clientName={clientInfo.name}
            clientCountry={clientInfo.country}
          />
          <Button
            variant="outlined"
            sx={styles.ExportButton}
            onClick={handleDownload}
          >
            <IconComponent icon="download" size="12px" />
            &nbsp;Export
          </Button>
        </Box>
        <FixedTablecomponent
          columns={[
            "Register date",
            "Email",
            "Phone",
            "Platform",
            "Balance",
            "Leverage",
          ]}
          rows={[
            clientInfo.registration_date || "",
            clientInfo.email || "",
            clientInfo.phone || "",
            clientInfo.server || "",
            clientInfo.balance || "",
            clientInfo.leverage || "",
          ]}
        />

        <TapComponent
          onTabstate={(value) => handleTapStatechange(value)}
          tapsContent={["Open Trades", "History"]}
        />

        <CustomTapPanelComponent value={tabstate} index={0}>
          <Box sx={{ marginTop: "32px" }}>
            <SearchBoxComponent
              rows={rowsOpenTrades}
              onFilteredRowsChange={handleFilteredRows}
              placeholder="Search by ticket or symbol"
              filterFields={["ticket", "symbol"]}
            ></SearchBoxComponent>
          </Box>
          <Box style={{ height: 600, width: "100%", marginTop: 30 }}>
            <TableComponent
              rows={filteredRowsOpenTrades}
              columns={columnsOpenTrades}
            />
          </Box>
        </CustomTapPanelComponent>
        <CustomTapPanelComponent value={tabstate} index={1}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "10px",
            }}
          >
            <Box display="flex" p={0}>
              <TitledSelector
                title="Period of time"
                value={dateSelect}
                handleChange={handleDateSelector}
                options={dateOptions}
              />
              {showDataPickers && (
                <>
                  <DateSelector
                    title="From"
                    value={fromDate}
                    handleChange={handleFromChange}
                  />
                  <DateSelector
                    title="To"
                    value={toDate}
                    handleChange={handleToChange}
                  />
                </>
              )}

              <Button sx={styles.ApplyButton} onClick={handleRequestHistory}>
                Apply
              </Button>
            </Box>
            <Box sx={{ right: "20px", margin: "25px 0 0 0" }}>
              <SearchBoxComponent
                rows={rowsHistory}
                onFilteredRowsChange={handleFilteredRowsHistory}
                placeholder="Search by ticket or symbol"
                filterFields={["ticket", "symbol"]}
              />
            </Box>
          </Box>
          <Box style={{ height: 600, width: "100%", marginTop: "20px" }}>
            <TableComponent
              rows={filteredRowsHistory}
              columns={columnsHistory}
            />
          </Box>
        </CustomTapPanelComponent>
      </Paper>
    </Box>
  );
}

export default ClientShowPage;
