import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css'
import CookieChecker from "./Tools/CookieChecker";
import NavbarLayout from "./components/NavAndSide/NavbarLayout";
import NavbarAndSidebar from "./components/NavAndSide/NavbarAndSidebar";
import ClientIndexPage from "./Pages/Clients/ClientIndexPage";
import ClientShowPage from "./Pages/Clients/ClientShowPage";
import WalletIndexPage from "./Pages/Wallet/WalletIndexPage";
import TreesIndexPage from "./Pages/Trees/TreesIndexPage";
import TreesCreatePage from "./Pages/Trees/TreesCreatePage";
import TreeCreateStepOnePage from "./Pages/Trees/TreeStepsPage/TreeCreateStepOnePage";
import TreeCreateStepOneOptionalPage from "./Pages/Trees/TreeStepsPage/TreeCreateStepOneOptionalPage";
import TreeCreateStepTwoPage from "./Pages/Trees/TreeStepsPage/TreeCreateStepTwoPage";
import DashboardIndexPage from "./Pages/Dashboard/DashboardIndexPage";
import LoginView from "./Pages/Login/LoginView";
import RegisterPage from "./Pages/Login/RegisterPage";
import ReferralForm from "./Pages/ReferralLinks/ReferralLinkPage";
import BackofficePage from "./Pages/BackofficeRequest/BackofficePage";
import WithdrawalRequestPage from "./Pages/BackofficeRequest/WithdrawalRequestPage";
import Chat from "./Pages/BackofficeRequest/Chat.js";
import TransferRequestPage from "./Pages/BackofficeRequest/TransferRequestPage";
import RequestStatusPage from "./Pages/BackofficeRequest/RequeststatusPage";
import NotFound from "./Pages/OtherPages/notFound";
import SettingsPage from "./Pages/Settings/SettingsPage";
import PasswordSettingsPage from "./Pages/Settings/SettingsOption/PasswordSettingsPage";

function App(props) {

return (
  <BrowserRouter>
    <Routes>
      <Route exact path={"/register"} element={ <NavbarLayout type={"register"}><RegisterPage/></NavbarLayout> } />
      <Route path={"/login"} element={ <NavbarLayout><LoginView/></NavbarLayout> } />           
      <Route path={"*"} element={
        <CookieChecker>
          <Routes>
            <Route path={"/"} element={ <NavbarAndSidebar sidebarOption="Dashboard"><DashboardIndexPage/></NavbarAndSidebar> } />
            <Route path={"/dashboard"} element={ <NavbarAndSidebar sidebarOption="Dashboard"><DashboardIndexPage/></NavbarAndSidebar> } />
            <Route exact path={"/my-clients"} element={ <NavbarAndSidebar sidebarOption="Clients"><ClientIndexPage/></NavbarAndSidebar> } />
            <Route exact path={"/my-clients/:id"} element={ <NavbarAndSidebar sidebarOption="Clients"><ClientShowPage/></NavbarAndSidebar> } />
            <Route exact path={"/my-wallet"} element={ <NavbarAndSidebar sidebarOption="Wallet"><WalletIndexPage/></NavbarAndSidebar> } />
            <Route exact path={"/my-trees"} element={ <NavbarAndSidebar sidebarOption="My trees"><TreesIndexPage/></NavbarAndSidebar> } />
            <Route exact path={"/my-trees/create"} element={ <NavbarAndSidebar sidebarOption="My trees"> <TreesCreatePage/> </NavbarAndSidebar> } />
            <Route exact path={"/my-trees/create/step-one"} element={ <NavbarAndSidebar sidebarOption="My trees"> <TreeCreateStepOnePage/> </NavbarAndSidebar> } />
            <Route exact path={"/my-trees/create/step-one-optional"} element={ <NavbarAndSidebar sidebarOption="My trees"> <TreeCreateStepOneOptionalPage/> </NavbarAndSidebar> } />
            <Route exact path={"/my-trees/create/step-two"} element={ <NavbarAndSidebar sidebarOption="My trees"> <TreeCreateStepTwoPage/> </NavbarAndSidebar> } />
            <Route exact path={"/my-trees/create/step-three"} element={ <NavbarAndSidebar sidebarOption="My trees"> <TreesCreatePage/> </NavbarAndSidebar> } />
            <Route exact path={"/referral-links"} element={ <NavbarAndSidebar sidebarOption="Referral Links"><ReferralForm/></NavbarAndSidebar> } />
            <Route exact path={"/requests"} element={ <NavbarAndSidebar sidebarOption="Requests"><BackofficePage/></NavbarAndSidebar> } />
            <Route exact path={"/requests/withdrawal"} element={ <NavbarAndSidebar sidebarOption="Requests"><WithdrawalRequestPage/></NavbarAndSidebar> } />
            <Route exact path={"/requests/transfer"} element={ <NavbarAndSidebar sidebarOption="Requests"><TransferRequestPage/></NavbarAndSidebar> } />
            <Route exact path={"/requests/status"} element={ <NavbarAndSidebar sidebarOption="Requests"><RequestStatusPage/></NavbarAndSidebar> } />  
            <Route exact path={"/requests/chat/:idChat"} element={ <NavbarAndSidebar sidebarOption="Requests"><Chat/></NavbarAndSidebar> } /> 
            <Route exact path={"/settings"} element={ <NavbarAndSidebar sidebarOption="SettingsPage"><SettingsPage/></NavbarAndSidebar> } />
            <Route exact path={"/settings/password"} element={ <NavbarAndSidebar sidebarOption="SettingsPage"><PasswordSettingsPage/></NavbarAndSidebar> } />
            <Route exact path="*" element={<NotFound/>} />
          </Routes>
        </CookieChecker>}/>
      <Route exact path="*" element={<NotFound/>} />
    </Routes>
  </BrowserRouter>    
);
}

export default App;
