import {
  Typography,
  IconButton,
  Card,
  CardContent,
  CardActions,
  Box,
} from "@mui/material";
import { ArrowForward, WidthFull } from "@mui/icons-material";
import { Link } from "react-router-dom";
import * as TextVariables from "../../assets/Variables/TextVariables.js";
import {Spaces} from "../../assets/Variables/SpaceVariables.js";
import Colors from "../../assets/Variables/ColorsVariables.js";

function CardBackOffice({ _icon, title, linkTo, visible }) {
  return (
    <Card
      sx={{
        padding: `20px ${Spaces.Size_6}`,
        height: "132px",
        borderRadius: Spaces.Size_2,
        border: `1px solid ${Colors.Secondary_50}`,
        boxShadow: !visible ? "none" : "0px 4px 6px -2px rgba(0, 0, 0, 0.10), 0px 6px 6px 0px rgba(0, 0, 0, 0.04), 0px 10px 15px 0px rgba(0, 0, 0, 0.04)",
        backgroundColor: !visible ? Colors.DisabledBackground : "inherit",
        color: !visible ? Colors.Gray_400 : "inherit",
        pointerEvents: !visible ? "none" : "auto",
        "&:hover": {
          boxShadow: !visible ? "none" : "0px 4px 6px -2px rgba(0, 0, 0, 0.10), 0px 6px 6px 0px rgba(0, 0, 0, 0.04), 0px 10px 15px 0px rgba(0, 0, 0, 0.04)"
        }
      }}
    >


      <Link
        to={(visible === undefined || visible === true) ? linkTo : null}
        style={{
          display: "flex",
          flexDirection: "column",
          textDecoration: "none",
          color: "inherit",
          gap: Spaces.Size_4,
        }}
      >
        <CardContent
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            flex: "1 1 0",
            padding: "0px",
          }}
        >
          <Box sx={{ width: 32, height: 32 }}>
            <span
              className="material-symbols-outlined"
              style={{ fontSize: "32px" }}
            >
              {_icon}
            </span>
          </Box>
          <Box sx={{ width: 32, height: 32 }}>
            <span
              className="material-symbols-outlined"
              style={{ fontSize: "24px" }}
            >
              chevron_right
            </span>
          </Box>
        </CardContent>
        <CardContent
          style={{
            padding: "0px",
          }}
        >
          <Typography sx={TextVariables.Body_4_Bold}>{title}</Typography>
        </CardContent>
      </Link>
    </Card>
  );
}

export default CardBackOffice;
