import React, { useState, useEffect } from "react";
import { Box, Button, Snackbar, Typography, Paper } from "@mui/material";
import copy from "clipboard-copy";
import * as styles from "../ReferralLinks/Styles.js";
import * as TextVariables from "../../assets/Variables/TextVariables.js";
import TableComponent from "../../components/Table/TableComponent.js";
import Colors from "../../assets/Variables/ColorsVariables.js";
import {useCookies} from "../../Tools/CookieChecker.js";
import ReferralLinkRequest from "../../Endpoints/ReferralLinkRequest.js";
import { RemoveDashboardData } from "../../Tools/LocalStorage.js";
import {mainBox} from "../../assets/Variables/SpaceVariables.js";

const registerUrlBase = "https://cabinet.tvmarkets.com/en/register/1/live-account";

function TreesIndexPage(props) {
  const [copiedId, setCopiedId] = useState(null); // Estado para mantener el ID de la fila copiada
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const { user, type, token, id } = useCookies();
  const [rows, setRows] = useState([]);

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const RequestReferralLinkData = async() => {
    try{
        const response = await ReferralLinkRequest({enable: true});
        if(response.data != null){
            let storedTrees = response.data.data.trees;    
            if (storedTrees && Array.isArray(storedTrees) && storedTrees.length > 0) { 
              let actualReferral = storedTrees.map((referral, index) => {
                if(response.data.data.role === "IB"){
                  const regex = /\(([^)]+)\)/; 
                  const coincidence = referral.description.match(regex);
                  referral.description = coincidence ? coincidence[1] : referral.description
                }
                let row = {
                    id: referral.id,
                    tree_name: referral.description,
                    language: referral.language,
                    entity: referral.entity_initials,
                    link: referral.link,
                    prefix: referral.login_prefix
                }
                return row;
              });
              setRows(actualReferral);
            }
        }
      }catch (error){
        console.log(error);
        RemoveDashboardData();
    }
  }

  useEffect(() => {
    RequestReferralLinkData();
  }, []);

  const handleDoubleRowClick = () => { };

  const columns = [
    {
      field: "tree_name",
      headerName: "Tree Name",
      headerClassName: "header_table",
      renderHeader: () => <>{"Tree Name"}</>,
    },
    {
      field: "language",
      headerName: "Language",
      headerClassName: "header_table",
      renderHeader: () => <>{"Language"}</>,
    },
    {
      field: "entity",
      headerName: "Entity",
      headerClassName: "header_table",
      renderHeader: () => <>{"Entity"}</>,
    },
    {
      field: "link",
      headerName: "Link",
      headerClassName: "header_table",
      renderHeader: () => <>{"Link"}</>,
      sortable: false,
      filterable: false,
      hideable: false,
      resizable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const handleCopy = () => {
          if(process.env.REACT_APP_WEB_API_URL == "https://api-crm-portal.tvmarkets.com/api"){
            copy(`${registerUrlBase}?ib=${id}&ib_tree=${params.row.id}&lang=${params.row.language}&prefix=${params.row.prefix}`);
          }
          else{
            const domain = window.location.origin;
            copy(`${domain}/register?ib=${id}&ib_tree=${params.row.id}&lang=${params.row.language}&prefix=${params.row.prefix}`);
          }

          setTimeout(() => {
            setCopiedId(params.id); // Cambiar el estado del ID copiado
          }, 0); // 2000 ms = 2 segundos
          setTimeout(() => {
            setCopiedId(null); // Restablecer el ID copiado después de 2 segundos
          }, 2000); // 2000 ms = 2 segundos
        };

        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height:'100%',
              width: "100%",
            }}
          >
            <Button
              sx={{
                ...styles.linkButton, // Incorporar los estilos de styles.linkButton
                backgroundColor:
                  copiedId === params.id
                    ? Colors.Secondary_800
                    : "rgba(164, 176, 189, 0.25)",
                color: copiedId === params.id ? "white" : "black",
                "&:hover": {
                  backgroundColor:
                    copiedId === params.id ? Colors.Secondary_800 : Colors.Secondary_300,
                  color: "white", // Asegúrate de que el texto sea legible en el hover
                },
                transition: "background-color 0.6s ease, color 0.6s ease", // Transiciones suaves
              }}
              onClick={handleCopy}
            >
              <span
                className="material-symbols-outlined"
                style={{ fontSize: "12px", marginRight: "2px" }}
              >
                content_copy
              </span>
              {copiedId === params.id ? "Copied" : "Copy referral link"}
            </Button>
          </Box>
        );
      },
    },
  ];

  return (
    <Box sx={mainBox}>
      <Paper elevation={2} sx={styles.mainPaper}>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={openSnackbar}
          autoHideDuration={2000}
          onClose={handleSnackbarClose}
          message="The link was successfully copied to your clipboard"
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
          }}
        >
          <Typography sx={TextVariables.Body_3_Bold}>Referral Links</Typography>
          <Typography sx={TextVariables.Body_4}>
            Share your links to invite clients to your trees and earn
            commissions! If you need a new link for your tree, you may request
            one from your broker
          </Typography>
        </Box>
        <Box style={{ height: 712, width: "100%" }}>
          <TableComponent
            handleDoubleRowClick={handleDoubleRowClick}
            rows={rows}
            columns={columns}
          />
        </Box>
      </Paper>
    </Box>
  );
}

export default TreesIndexPage;
