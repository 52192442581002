import React, { useEffect, useState } from "react";
import { Box, Paper, Typography, Avatar, Divider } from "@mui/material";
import { Spaces } from "../../assets/Variables/SpaceVariables.js";
import Colors from "../../assets/Variables/ColorsVariables.js";
import * as TextVariables from "../../assets/Variables/TextVariables.js";
import * as masterData from "../../MasterData/MasterData.js";

function ProfileCard(props) {
  const flag = masterData.flags.find((assetFlag) => assetFlag.value === props.asset);

  return(
    <Paper
      elevation={1} 
      sx={{ 
        p: 3,
        borderRadius: "20px",
        border: "1px solid #ECF1F7",
        background: "rgba(236, 241, 247, 0.50)",
        }}
    >
      <Box sx={{display:'flex'}}>
        <Avatar
          sx={{
            alt: props.avatarInfo.letter,
            bgcolor: props.avatarInfo.color,
            width: 80,
            height: 80,
            fontSize: "30px"
          }}
        >
          {props.avatarInfo.letter}
        </Avatar>
        <Box
          sx={{
            paddingLeft:'20px'
          }}
        >
          <Typography sx={TextVariables.Body_3_bold} mt={2}>
            {props.userInfo.name}
          </Typography>
          <Typography sx={TextVariables.Body_4} color="text.secondary">
            Colombia
          </Typography>
        </Box>
      </Box>
      <Box sx={{marginTop:'24px'}}>
        <Typography sx={TextVariables.Caption_1}>Your balance</Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: Spaces.Size_3,
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: Spaces.Size_1,
          }}
        >
          <Typography sx={{ fontFamily: TextVariables.Subtitle_2 }}>
            ${props.userInfo.balance}
          </Typography>
          <Typography
            sx={{
              fontFamily: TextVariables.Body_4,
              color: Colors.Secondary_700,
              padding: "15px 0 0 0",
            }}
          >
            {props.userInfo.asset}
          </Typography>
        </Box>

        <Box sx={{ width: "35px", borderRadius: "35px" }}>
          <Avatar
            alt="asset flag"
            src={flag.flag}
            sx={{ 
              width: 35, 
              height: 35,
            }}
          ></Avatar>
        </Box>
      </Box>
      
      <Divider 
        sx={{
          borderTop:"1px solid rgba(107, 107, 107, 0.88)", 
          boxShadow:"",
          margin:'15px 0px'
        }}
      />
      <Typography sx={TextVariables.Body_4} mt={1}>
        Clients: <Box component="span" fontWeight="600">{props.userInfo.clients}</Box>
      </Typography>
      <Typography sx={TextVariables.Body_4} mt={1}>
        Type: <Box component="span" fontWeight="600">{props.userInfo.type}</Box>
      </Typography>
      <Typography sx={TextVariables.Body_4} mt={1}>
        Email: <Box component="span" fontWeight="600">{props.userInfo.email}</Box>
      </Typography>
    </Paper>
  );
}

export default ProfileCard;