import React, { useEffect, useState } from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { mainBox } from "../../assets/Variables/SpaceVariables.js";
import * as TextVariables from "../../assets/Variables/TextVariables.js";
import { useCookies } from "../../Tools/CookieChecker.js";
import ProfileCard from "../../components/Cards/ProfileCard.js";
import WalletRequest from "../../Endpoints/WalletRequest.js";
import { GetDashboardData } from "../../Tools/LocalStorage.js";
import CardBackOffice from "../../components/Cards/CardBackOffice.js";

const settingsOptions = [
  {
    title: "Your mailing subscriptions",
    linkTo: "/settings/mail-suscriptions",
    icon: "mail",
    visible: false
  },
  {
    title: "Your email settings",
    linkTo: "/settings/email-settings",
    icon: "mail_lock",
    visible: false
  },
  {
    title: "Your password settings",
    linkTo: "/settings/password",
    icon: "password",
    visible: true
  },
  {
    title: "Set your 2-factor authentication",
    linkTo: "/settings/two-factor-auth",
    icon: "encrypted",
    visible: false
  }
];

function SettingsPage(props) {
  const { user, name, type, token } = useCookies();
  const [avatarInfo, setAvatarInfo] = useState({color:'', letter:''});
  const [userInfo, setUserInfo] = useState({name:'', email:'', type:'', balance:0, asset:"USD", clients: 0});

  const CalculateAvatarValues = (string) => {
    let color = "#";
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    setAvatarInfo({
      color: color,
      letter: string.split(" ")[0][0]
    });
  }

  const RequestWalletData = async () => {
    try{
      const response = await WalletRequest();
      if (response.data.data != null) {
        setUserInfo((prevState) => ({...prevState, balance: parseFloat(response.data.data.wallet.balance).toFixed(2)}));
      }
      else{
        setUserInfo((prevState) => ({...prevState, balance: 0}));
      }
    }
    catch(error){
      setUserInfo((prevState) => ({...prevState, balance: 0}));
      console.log(error);
    }
  }

  useEffect(() => {
    if(user){
      CalculateAvatarValues(name);
      const dashboardData = GetDashboardData();
      setUserInfo({
        name: name,
        email: user,
        type: type,
        balance:0, 
        asset:"USD",
        clients: (dashboardData.dashboard.total_accounts) ? dashboardData.dashboard.total_accounts : 0
      });
      RequestWalletData();
    }
  }, [user]);

  return (
    <Box sx={mainBox}>
      <Paper elevation={2} sx={{ p: 3, borderRadius: "8px" }}>
        <Box sx={{ mb: 4 }}>
          <Typography sx={TextVariables.Subtitle_4} gutterBottom>
            Your account preferences
          </Typography>
          <Typography sx={TextVariables.Body_4} color="text.secondary">
            Welcome this is your profile page. Here you can see your clients and their movements.
          </Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8} lg={4}>
            <ProfileCard
              avatarInfo = {avatarInfo}
              userInfo = {userInfo}
              asset = "USD"
            />
          </Grid>
          <Grid item xs={12} md={4} lg={8}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography sx={TextVariables.Body_3_bold} gutterBottom>
                  Set your account preferences
                </Typography>
              </Grid>
              {settingsOptions.map((value, index) =>
                <Grid key={index} item xs={12} sm={12} md={12} lg={6}>
                  <CardBackOffice
                    _icon={value.icon}
                    title={value.title}
                    linkTo={value.linkTo}
                    visible={value.visible}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Box
          sx={{
            marginBottom:"100px"
          }}
        >

        </Box>
      </Paper>
    </Box>
  );
}

export default SettingsPage;
